.t-c {
   /*  background:#131519; */
    color:#fff;
    padding:1.5em 0;
    border-radius: 1em;
    font-size: 12px;
    line-height: 14px;
}

.t-c h4 {
    color:#ddd;
}

.tc-content{
    max-height:36vh;
    overflow: auto;
}

.tc-content strong{
  font-weight: 500;
}

.tc-content::-webkit-scrollbar {
    width: 8px;
    background:none;
  }

.tc-content::-webkit-scrollbar-thumb 
{
   border-radius: 8px;
    background: rgba(255, 255, 255, 0.3);
  }


  .form-control{
    color: #fff!important;
    height: 45px;
    background-color: transparent!important;
    border-radius: .25em;
    border: 2px solid #be1fda;
    margin-bottom: 20px;
  }

  label.tnclink{
    color:#be1fda;
    font-weight:400;
    
    cursor: pointer;
  }

  label.tnclink:hover{
    font-weight:500;
    text-decoration: underline;
  }

