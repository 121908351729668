.custom-modal {
  position: fixed;
  display: flex;
  padding-top: 120px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  min-height: 500px;
  transition: all 0.3s ease-out;
}

.custom-modal.show {
  display: flex;
  animation: openModal 0.4s ease-out forwards;
}

.custom-modal.hide {
  animation: closeModal 0.4s ease-out forwards;
  pointer-events: none;
}

.custom-modal.gone {
  display: none;
}

.custom-modal.fullscreen {
  z-index: 1999;
  padding: 2.5%;
}

@keyframes openModal {
  0% {
    /* transform: translateY(-100%); */
    opacity: 0;
  }
  100% {
    /* transform: translateY(0); */
    opacity: 1;
  }
}

@keyframes closeModal {
  0% {
    /* transform: translateY(0); */
    opacity: 1;
  }
  100% {
    /* transform: translateY(-120%); */
    opacity: 0;
  }
}

.custom-modal .child-container {
  position: relative;
  z-index: 1000;
  overflow-y: auto;
  margin: auto;
}

.custom-modal.fullscreen .child-container {
  height: 100%;
  width: 100%
}

.custom-modal.fullscreen .img-container {
  height: 100%;
  overflow: scroll;
  display: flex;
}

.custom-modal .fullscreen-img {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
  margin: auto;
  /* min-height: 500px; */
}

@media (max-width: 992px) {
  .custom-modal {
    position: fixed;
    display: relative;
  }
  .custom-modal .child-container {
    margin-top: 5%;
    margin-bottom: 5%;
    height: 90%;
    overflow-y: scroll;
  }
}
.custom-modal .close-btn {
  position: absolute;
  top: 1%;
  right: 2%;
}
.custom-modal .close-btn img {
  filter: brightness(0) saturate(0) invert(0) sepia(0%) saturate(0%)
    hue-rotate(0) brightness(0%) contrast(93%);
  height: 3rem;
  right: 0;
  top: 0;
}
.custom-modal .close-btn:hover {
  opacity: 0.6;
}
.custom-modal .close-btn:active {
  transform: translateY(1px);
}

.custom-modal.fullscreen .close-btn {
  padding: 0;
  line-height: 1;
  top: 0;
  right: 3%;
  background: none;
  border: none;
  outline: none;
}
.custom-modal.fullscreen .close-btn:hover {
  border-color: transparent;
  border: none;
}
.custom-modal.fullscreen .close-btn img {
  filter: none;
}

#overlayBg {
  z-index: 1;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}

.modal-blocker {
  z-index: 10000;
  background: rgba(255, 255, 255, 0.2);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}

.modal-blocker-2 {
  z-index: 10000;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}