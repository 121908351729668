.wallet-token-image {
  background: #333;
  position: absolute;
  width: 100%;
  top: 10%;
  height: 60%;
  z-index: 5;
  overflow: hidden;
  background-size: contain;
}

.wallet-token-image .percent {
  position: absolute;
  width: 100%;
  height: 19%;
  top: 32.5%;
  left: 0;
  font-family: subspace;
  text-align: center;
  font-size: 2rem;
  line-height: 4rem;
  vertical-align: middle;
  color: white;
}

.padd {
  min-height: 80vh;
}

.wallet-title {
  font-size: 3.1rem;
  line-height: 3.1rem;
}

@media (max-width: 706px) {
  .wallet-title {
    font-size: 1.9rem;
    line-height: 2rem;
  }
}

.wallet-filter-item.active,
.wallet-filter-item:hover {
  color: #e5268f;
  border-color: #e5268f;
}
.wallet-filter-item {
  color: white;
  font-size: 0.7rem;
  border-bottom: solid 3px white;
  cursor: pointer;
  transition: all 0.3s ease-out;
  text-transform: uppercase;
}
