.padd {
    min-height: 80vh;
}

h3.prize {
    font-size: 1.6em;
}

.os a {
    margin-left:0.5rem;
    color: #d2d2d2;
    font-weight: 600;
    transition: color 0.3s ease-out;
   }
.os a:hover {
color: #ffffff;
text-decoration: none;
}


.drop-enter-container{
    border: solid 2px;
    border-top-color: #f1f5036b;
    border-left-color: #f1f5036b;
    border-bottom-color: #1b1d0180;
    border-right-color: #1b1d0180;
    border-radius: 0.25rem;
    color: white;
}

.counter-title{
    font-size: 0.8rem;
    text-transform: uppercase;
}

.counter-values{
    font-size: 0.8rem;
    color: white;
    margin: 0;
}

.owner-benefits{
    font-size: 0.8rem;
    text-transform: uppercase;
}

.sale-card {
    width: 100%;
    padding-top: 136%;
    position: relative;
    overflow: hidden;
    border-radius: 0.8rem;
  }
  
  .sale-card img {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
  }
  
  .sale-card video {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
  }

  
  @media (max-width: 706px) {
    .ge-title {
        font-size: 1.9rem;
    }
  }