span.ripple {
  position: absolute; /* The absolute position we mentioned earlier */
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 400ms linear;
  background-color: rgba(255, 255, 255, 0.4);
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}
