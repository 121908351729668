
  
  .faq-item button{
    width:100%;
    border: none;
    background: none;
    outline: none;
    box-shadow: none;
  }
  
  .faq-item{
    cursor: pointer;
  }
  
  .faq-item .item-head h3{
    margin: 0;
    font-size: 1em;
    font-weight: 500;
    color:#fff;
    padding-right: 2em;
      text-align: left;
      cursor: pointer;
  }
  
  .faq-item .item-head.collapsed h3{
    color: #969da3;
  }
  .faq-item button{
    cursor: pointer;
  }
  .faq-item button span{
    cursor: pointer;
    display: block;
    width: 1.15em;
    height: 1.15em;
    min-width: 1.15em;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
  }
  
  .faq-item button{margin-bottom:1rem;}
  .faq-item button.collapsed{margin-bottom:0;}

  .faq-item button.collapsed span{
    background-position: top;
  }

  .faq-item .item-content{
      overflow: hidden;
      height: auto;
      max-height: 700px;
      transition: all 0.2s ease-out;
      cursor: default;
  }

  .faq-item .item-content.coll{
    max-height: 0px;
    padding: 0;
}

.faq-item .item-content a{
  color: #be1fda;
  font-weight: 400;
  cursor: pointer;
}