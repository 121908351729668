#footer {
  background: #000;
  padding: 20px 20px 50px 20px;
  border-top: solid 1px #fff;
}

#footer img {
  height: 26px;
  margin-left: 10px;
  transition: opacity 100ms linear;
}

#footer img:hover {
  opacity: 0.8;
}

.copy-right:before {
  content: "© ";
}

#footer p {
  font-size: 1rem;
  margin-bottom: 0;
  color: #fff;
}

#footer a {
  color: #00b5cc;
}

/* 
.footer-light h5 {
  margin-top: 0;
  font-family: "Poppins";
  font-weight: 600;
  -webkit-text-fill-color: white;
  color: #35404e;
}

.footer-container {
  display: flex;
  text-align: center;
  padding: 30px 15px;
  z-index: 10;
  padding: 0 !important;
}
.footer-container img:hover {
  filter: brightness(0) saturate(100) invert(23%) sepia(93%) saturate(5853%)
    hue-rotate(286deg) brightness(98%) contrast(93%);
}
.footer-container #contactUs,
.footer-container #copyright {
  display: flex;
}
.footer-container #contactUs {
  margin-left: auto;
}
.footer-container #copyright .c-symbol {
  font-size: 24px;
  margin-right: 10px;
}
.footer-container #copyright p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  align-self: center;
}
.footer-container #contactUs p {
  margin-top: 5px;
  color: white;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  align-self: center;
  margin-right: 10px;
}
.footer-container #contactUs a {
  margin-right: 10px;
} */

input.email-form {
  height: 30px;
  width: 70%;
  color: #fff;
  background: rgba(20, 20, 20, 0.7);
  font-size: 0.7rem;
  border: none;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  font-style: italic;
}

input.email-form:focus-visible {
  outline: none;
}

.email-form-btn {
  height: 30px;
  background: grey;
  color: #fff;
  border: none;
  align-items: center;
}

.email-form-btn:focus-visible,
.email-form-btn:focus {
  outline: none;
}

@media (max-width: 992px ) {
  input.email-form {
    width: 85%;
  }
}

@media (max-width: 576px) {
  input.email-form {
    width: 100%;
  }
}
