#forgeField {
  overflow: hidden;
}

#forgeField a {
  color: #00b5cc;
}

.forge-container {
  padding: 0;
  border: solid 2px #c7c7c7ac;
  background-image: url(../../assets/images/forge/field.png);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 100% 100%;
  width: 100%;
  padding-top: 58%;
  position: relative;
}

.forge-token-container {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border: solid 2px #c7c7c7ac;
  transition: transform 200ms ease-in-out;
  transform: translate3d(0, 100%, 0);
  background-color: #353535;
  /* scrollbar-width: thin; */
  /* scrollbar-color: transparent #c7c7c7ac; */
  margin: 0;
  padding: 0;
  list-style: none;
}

.forge-token-container:before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background-image: url(../../assets/images/hero/header_image_lamelo.png);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
}
/* 
.forge-token-container::-webkit-scrollbar {
  width: 6px;
}
.forge-token-container::-webkit-scrollbar-track {
  background: #c7c7c741;
}
.forge-token-container::-webkit-scrollbar-thumb {
  background-color: #8a8a8a;
  background-image: linear-gradient(315deg, #e7eff983 0%, #cfd6e68c 74%);
  border-radius: 8px;
  border: none;
} */

.forge-token-container.show {
  transform: translate3d(0, 0, 0);
}

.forge-token-header {
  position: relative;
  border-bottom: solid 2px #c7c7c7ac;
  background: linear-gradient(
    110deg,
    rgba(200, 200, 200, 0.4) 0%,
    rgba(200, 200, 200, 0.1) 74%,
    rgba(200, 200, 200, 0.6320903361344538) 100%
  );
  cursor: pointer;
}
.forge-close-btn {
  display: inline-flex;
  position: absolute;
  right: 1%;
  top: 0;
  bottom: 0;
  cursor: pointer;
  height: 50%;
  opacity: 0.8;
  margin-top: auto;
  margin-bottom: auto;
  transition: all 100ms ease-in-out;
}
.forge-close-btn:hover {
  opacity: 0.5;
}

.forge-close-btn img {
  height: 100%;
}

.tokens-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  align-self: center;
  padding: 30px;
  overflow: auto;
  height: 100%;
}

.dots-left,
.dots-right,
.dots-top,
.dots-bottom {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: url(../../assets/images/forge/dots-left.png);
  background-position: left top;
  background-size: 30%;
  background-repeat: repeat-y;
}

.dots-top {
  display: none;
  background-position: top;
  background-image: url(../../assets/images/forge/dots-top.png);
  background-repeat: repeat-x;
  background-size: 1.5%;
}
.dots-bottom {
  display: none;
  background-position: bottom;
  background-image: url(../../assets/images/forge/dots-bottom.png);
  background-repeat: repeat-x;
  background-size: 1.5%;
}

.dots-right {
  background-position: right top;
  background-image: url(../../assets/images/forge/dots-right.png);
}

.forge-title {
  width: 100%;
  position: absolute;
  left: 0;
  top: 10%;
  font-size: 3vw;
}

.target-box {
  width: 20%;
  height: 40%;
  position: absolute;
}

.target-box.blue {
  /* background: #040f609c; */
  top: 6.8%;
  left: 4.8%;
}

.target-box.red {
  /* background: #610101a6; */
  bottom: 6.8%;
  left: 4.8%;
}

.target-box.gold {
  /* background: #3e3d05cc; */
  bottom: 6.8%;
  right: 4.8%;
}

.target-box.silver {
  /* background: #2424249c; */
  top: 6.8%;
  right: 4.8%;
}
.target-box.minter {
  /* background: linear-gradient(
    0deg,
    rgb(39 120 51 / 37%) 0%,
    rgb(86 130 255 / 60%) 100%
  ); */
  /* background: linear-gradient(
    110deg,
    rgba(255, 255, 255, 0.7) 0%,
    rgba(255, 255, 255, 0.37) 70%,
    rgba(255, 255, 255, 0.7) 100%
  ); */
  top: 22%;
  left: 38%;
  width: 24%;
  height: 56%;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: stretch;
  justify-content: space-around;
  align-items: center;
}
.target-box.minter h5 {
  font-size: 0.9vw;
}
.target-box.minter .video-container {
  position: relative;
  width: 100%;
  padding-top: 120%;
  background: rgba(0, 0, 0, 0.5);
}
.target-box.minter .video-container video {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.target-box .title {
  font-size: 0.8rem;
  width: 76%;
  position: absolute;
  text-align: center;
  bottom: 2.5%;

  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;
  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.target-box.blue .title {
  /* color: #3d44eb; */
  background-color: rgb(255, 255, 255);
  background-image: linear-gradient(
    90deg,
    rgb(34, 34, 255) 0%,
    rgba(0, 0, 200, 0.226) 65%,
    rgb(34, 34, 255) 100%
  );
  left: 0;
}

.target-box.silver .title {
  /* color: #989898; */
  background-color: rgb(255, 255, 255);
  background-image: linear-gradient(
    90deg,
    rgb(179, 176, 176) 0%,
    rgba(200, 200, 200, 0.1) 74%,
    rgb(167, 167, 167) 100%
  );
  right: 0;
}

.target-box.red .title {
  /* color: #eb4e3d; */
  background-color: rgb(255, 97, 97);
  background-image: linear-gradient(
    90deg,
    rgb(255, 0, 0) 0%,
    rgba(255, 0, 0, 0.1) 54%,
    rgb(255, 0, 0) 100%
  );

  bottom: auto;
  top: 3%;
  left: 0;
}

.target-box.gold .title {
  /* color: #edd923; */
  background-color: #fff;
  background-image: linear-gradient(
    90deg,
    rgb(255, 200, 1) 0%,
    rgba(255, 200, 0, 0.384) 74%,
    rgb(255, 200, 1) 100%
  );
  bottom: auto;
  top: 3%;
  right: 0;
}

.target-moon,
.target-neptune,
.target-gold,
.target-mars {
  cursor: pointer;
}

.target-moon {
  position: absolute;
  height: 86%;
  width: 76%;
  top: 0;
  right: 0;
}
.target-neptune {
  position: absolute;
  height: 86%;
  width: 76%;
  top: 0;
  left: 0;
}

.target-gold {
  position: absolute;
  height: 86%;
  width: 76%;
  bottom: 0;
  right: 0;
}
.target-mars {
  position: absolute;
  height: 86%;
  width: 76%;
  bottom: 0;
  left: 0;
}

.target-remove {
  position: absolute;
  height: 20%;
  width: 20%;
  left: 40%;
  top: 40%;

  opacity: 1;

  -webkit-animation: fadeInFromNone 300ms ease-out;
  -moz-animation: fadeInFromNone 300ms ease-out;
  -o-animation: fadeInFromNone 300ms ease-out;
  animation: fadeInFromNone 300ms ease-out;

  transition: all 100ms ease-in-out;
}

.target-remove:hover {
  opacity: 0.8;
}

.target-remove:active {
  opacity: 0.8;
}

.target-help {
  font-family: "DrukWideBold";
  font-size: 1rem;
  line-height: 1rem;
  text-align: center;
  color: #ffffff;
}

.minted-holder {
  border: 2px solid #fff;
  height: 70%;
  width: 80%;
  overflow: hidden;
}

.minted-holder + button,
.minter-btn {
  font-family: "DrukWideBold";
  display: block !important;
  width: 80%;
  margin: 0.25vw auto 0.5vw auto;
  padding: 0;
  font-size: 0.9vw;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 0.3em;
  border: 2px solid #fff;
}

.minter-btn:focus-visible,
.minter-btn:focus,
.minted-holder + button:focus-visible,
.minted-holder + button:focus {
  outline: none;
}

.minter-btn {
  position: absolute;
  display: none !important;
  font-size: 0.8rem;
  max-width: 20%;
  min-width: 20%;
  bottom: 3%;
  left: 40%;
}

.card-container {
  width: 100%;
  position: relative;
  margin: 5px;
  max-width: 150px;
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  opacity: 1;
  transition: all 100ms ease-out;
}
.card-container:active {
  transform: translateY(-5px);
  opacity: 1;
}
.card-container:hover {
  transform: translateY(-5px);
  opacity: 1;
}
.card-container.selected {
  opacity: 1;
}
.card-container.selected:after {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url(../../assets/images/checkmark.svg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 40% 40%;
  -webkit-animation: fadeInFromNone 1s ease-out;
  -moz-animation: fadeInFromNone 1s ease-out;
  -o-animation: fadeInFromNone 1s ease-out;
  animation: fadeInFromNone 1s ease-out;
}

@-webkit-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@-moz-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@-o-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@media (min-width: 1200px) {
  #forge.container {
    max-width: 1000px;
  }
}

@media (max-width: 1250px) {
  .target-box .title {
    font-size: 0.7rem;
  }
}

@media (max-width: 992px) {
  #forge h2 {
    font-size: large;
  }
  .target-box .title {
    font-size: 0.9vw;
  }
}

@media (max-width: 768px) {
  .forge-container {
    padding-top: 110%;
    background-image: url(../../assets/images/forge/field-portrait.png);
  }
  .forge-token-container h2 {
    font-size: 1.8rem;
  }

  .dots-left,
  .dots-right {
    display: none;
  }
  .dots-top,
  .dots-bottom {
    display: block !important;
  }

  .minter-btn {
    display: block !important;
  }

  .target-box.minter {
    display: none !important;
  }

  .target-box {
    width: 35% !important;
    height: 40% !important;
  }

  .target-help {
    font-size: 1rem;
    line-height: 1.2rem;
  }
  .target-box .title {
    font-size: 1.6vw;
  }
  .target-box.blue {
    top: unset;
    left: unset;
    bottom: 50%;
    right: 50%;
  }
  .target-box.red {
    top: 50%;
    left: unset;
    bottom: unset;
    right: 50%;
  }
  .target-box.gold {
    top: 50%;
    left: 50%;
    bottom: unset;
    right: unset;
  }
  .target-box.silver {
    top: unset;
    left: 50%;
    bottom: 50%;
    right: unset;
  }

  .target-gold,
  .target-mars,
  .target-moon,
  .target-neptune {
    height: 81%;
  }

  .target-gold {
    top: 1px;
    left: 1px;
  }
  .target-mars {
    top: 1px;
    right: 0;
    left: unset;
  }
  .target-moon {
    bottom: 0;
    left: 1px;
    top: unset;
  }
  .target-neptune {
    bottom: 0;
    right: 0;
    left: unset;
    top: unset;
  }

  .target-box.blue .title {
    top: 10%;
    left: unset;
    right: 0;
    bottom: unset;
  }
  .target-box.red .title {
    top: unset;
    left: unset;
    right: 0;
    bottom: 10%;
  }
  .target-box.silver .title {
    top: 10%;
    left: 0;
    right: unset;
    bottom: unset;
  }
  .target-box.gold .title {
    top: unset;
    left: 0;
    right: unset;
    bottom: 10%;
  }
}

@media (max-width: 576px) {
  .target-gold,
  .target-mars,
  .target-moon,
  .target-neptune {
    height: 89%;
  }

  #forge .forge-token-header h5 {
    font-size: 0.8em;
  }
  .forge-container {
    padding-top: 130%;
  }
  .card-container {
    margin: 0;
    width: 50%;
    padding: 5px;
    min-width: unset;
    max-width: unset;
  }

  .forge-token-container h2 {
    font-size: 1.4rem;
  }
  .target-box .title {
    font-size: 2.4vw;
  }

  .target-box.blue .title {
    top: 5%;
  }
  .target-box.red .title {
    bottom: 4%;
  }
  .target-box.silver .title {
    top: 5%;
  }
  .target-box.gold .title {
    bottom: 4%;
  }

  .target-help {
    font-size: 0.9rem;
    line-height: 0.9rem;
  }

  .target-box {
    width: 50% !important;
    height: 44% !important;
  }

  .minter-btn {
    min-width: 26%;
    max-width: 26%;
    font-size: 2.4vw;
    bottom: 1%;
    left: 37%;
  }
}
