.padd {
    min-height: 80vh;
}

.dr-title {
    font-size: 3.1rem;
}

.filter-item{
    color: white;
    font-size: 0.7rem;
    border-bottom: solid 3px white;
    cursor: pointer;
    transition: all 0.3s ease-out;
    text-transform: uppercase;
}

.filter-item.active, .filter-item:hover {
    color: #E5268F;
    border-color: #E5268F;
}



.drop-item{
    border: solid 2px;
    border-radius: 0.3em;
    position: relative;
}

.drop-item-title{
    font-size: 0.85rem; 
    text-transform: uppercase;
}

.drop-content p{
    font-size: 0.85rem;
    line-height: 1rem;
    color: #fff;
}
/* 
.drop-content button {
    position: re;
    bottom: 0;
}
 */
.drop-image img {
    object-fit: contain;
    max-height: 134px;
}

.drop-item.raffle{
    background-color:#ffffff22; 
    border-top-color: #ffffff66;
    border-left-color: #ffffff66;
    border-bottom-color: #00000080;
    border-right-color: #00000080;
}

.drop-item.redeemable{
    background-color:#d8cc233c; 
    border-top-color: #f1f5036b;
    border-left-color: #f1f5036b;
    border-bottom-color: #1b1d0180;
    border-right-color: #1b1d0180;
}

.drop-counter {
    font-size: 0.6rem;
    line-height: 0.6rem;
    color:white;
    background: black;
    border-radius: 0.25rem;
    padding: 0.4rem 1rem;
    position: absolute;
    top: -0.7rem;
    left: 1rem;
}

.counter-label{
    white-space: nowrap;
}






@media (max-width: 992px) {
    .drop-content button {
        position: relative;
        bottom: inherit;
    }
  }




  @media (max-width: 706px) {
    .dr-title {
        font-size: 1.9rem;
        line-height: 2rem;
    }

    .coming-soon{
        font-size: 1.6rem;
        line-height: 1.8rem;
        margin: 0 auto;
    }
  }