/* Chrome, Safari, Edge, Opera */
input.numeric-input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.numeric-input[type="number"] {
  -moz-appearance: textfield;
}

:root {
  --white: #fff;
  --black: #000;
}

.button-group {
  color: #fff;

  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  border-radius: 4px;
  margin-top: 16px;
  margin-bottom: 16px;
  min-width: 34px;
}

.button-group .input-container {
  color: #fff;

  display: inline-flex;
  box-sizing: border-box;
  font-size: 14px;
  width: 20px;
  min-width: 20px;
  max-height: 36px;
  align-items: center;
  text-align: center;
  -webkit-text-align-last: center;
  text-align-last: center;

  padding: 0px;
  border-top: 2px solid;
  border-bottom: 2px solid;

  background: none;
}
.button-group input:focus {
  outline: none;
}
.button-group input {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
}

.button-group button {
  overflow: hidden;
  height: 36px;
  font-size: 26px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  outline: 0;
  margin: 0;
  line-height: 20px;
  text-transform: uppercase;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;

  cursor: pointer;
  user-select: none;
  vertical-align: middle;

  padding: 3px 9px;
  border-radius: 4px;
  border: 2px solid;

  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.button-group button:first-child {
  color: #fff;

  min-width: 34px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-color: transparent;
  border-right: 0px;
  margin-right: -1px;
}
.button-group button:last-child {
  color: #fff;

  min-width: 34px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
  border-left: 0px;
}

.button-group button:first-child:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(38, 153, 251, 0.04);
  border: 2px solid;
  border-right: 0px solid;
  border-right-color: currentColor;
}
.button-group button:last-child:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(38, 153, 251, 0.04);
  border: 2px solid;
  border-left: 0px solid;
}
.button-group button:first-child:focus,
.button-group button:last-child:focus {
  outline: none;
}
