#about h5 {
  font-size: 1rem;
}
#about img {
  height: 2.5rem;
  margin-left: 10px;
  transition: opacity 100ms linear;
}
#about img:hover {
  opacity: 0.7;
}
