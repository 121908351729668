@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap");

@font-face {
  font-family: "SFProText";
  src: url("assets/fonts/1c45e28f8e86cc89876f003b953cc3e9.eot");
  src: url("assets/fonts/1c45e28f8e86cc89876f003b953cc3e9.eot?#iefix")
      format("embedded-opentype"),
    url("assets/fonts/1c45e28f8e86cc89876f003b953cc3e9.woff2") format("woff2"),
    url("assets/fonts/1c45e28f8e86cc89876f003b953cc3e9.woff") format("woff"),
    url("assets/fonts/1c45e28f8e86cc89876f003b953cc3e9.ttf") format("truetype"),
    url("assets/fonts/1c45e28f8e86cc89876f003b953cc3e9.svg#SF Pro Text")
      format("svg");
}

.dw {
  font-family: "DrukWideBold";
}
.sf-pro {
  font-family: "SFProText";
}

.archivo {
  font-family: "Archivo Black", sans-serif;
}

.thumbnail {
  position: absolute;
  top: 0;
  z-index: -5000;
  width: 0px;
  height: 0px;
}
.thumbnail img {
  object-fit: cover;
  width: 0px;
  height: 0px;
}

.bn-onboard-custom {
  z-index: 2323;
}

.clickable {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.clickable:hover {
  color: #be1fda;
}

html,
body {
  font-family: "SFProText", sans-serif;
  font-weight: 300;
  background: #01070d;
  color: #969da3;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "DrukWideBold";
  font-weight: 400;
  color: #ffffff;
}
h4,
h5 {
  font-weight: 500;
  color: #ffffff;
}
h6 {
  font-size: 0.6rem;
  font-family: "DrukWideBold";
  font-weight: 100;
  color: #ffffff;
}

#root {
  height: 100%;
}
#application {
  padding-top: 80px;
  background: none;
  position: relative;
  min-height: 100%;
  /* padding-bottom: 100px; */
  background-size: 100% auto;
  background-repeat: no-repeat;
}
.btn.round {
  border-radius: 0.6rem;
}

.btn-outline {
  background: #00000066;
  border: solid 2px;
  border-color: white;
  color: white;
  text-transform: uppercase;
  padding-left: 2em;
  padding-right: 2em;
  padding-top: 1em;
  padding-bottom: 1em;
  font-size: 0.6em;
  font-family: "DrukWideBold";
}

.btn-outline:hover {
  background-color: black;
  color: white !important;
}

.btn-outline.focus,
.btn-outline:focus {
  outline: 0;
  box-shadow: none;
}

.d-inline {
  display: inline-block;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background: rgba(0, 0, 0, 0.6);
}

.modal-content {
  background-color: #3f3d3a;
}

.modal-header {
  border-bottom: 1px solid #949393;
}
.modal-footer {
  border-top: 1px solid #949393;
}

.close {
  color: #fff;
  text-shadow: 0 1px 0 #333;
  opacity: 0.5;
}

.toastContainer {
  z-index: 2000;
}

.text-peach {
  color: #be1fda;
}

.text-lilac {
  color: #771197;
}

.bold-text {
  font-weight: 400;
}

.bold-header {
  font-weight: 600;
}

/* modal overrides... */
.web3modal-provider-container {
  padding: 4px !important;
}
.web3modal-provider-icon {
  width: 32px !important;
  height: 32px !important;
}
.web3modal-provider-name {
  font-size: 1.1em !important;
  margin-top: 0.3em !important;
}
.web3modal-provider-description {
  font-size: 0.9em !important;
  margin: 0.2em 0px !important;
}
.web3modal-modal-card {
  max-width: 710px !important;
}

.top-0 {
  top: 0;
}
.start-0 {
  left: 0;
}
.text-underline {
  text-decoration: underline;
}

@keyframes colors {
  25% {
    color: rgba(255, 0, 0, 0.6320903361344538);
  }
  50% {
    color: rgba(0, 0, 200, 0.632090336134453);
  }
  75% {
    color: rgba(200, 200, 200, 0.6320903361344538);
  }
  100% {
    color: rgba(255, 199, 1, 0.63);
  }
}

.spinner-colors {
  animation: colors 2s ease-out infinite;
}
