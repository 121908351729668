.counter-container{
   /*  width:100%; */
}

.counter-container .item{
    
    text-align: center;
}
.counter-container .item .val{
    font-size: 2.2em;
    font-weight: 400;
    display: inline-block;
    margin-left: 0.5em;
    margin-right: 0.2em;
    color:white;
}
.counter-container .item .label{
    font-size: 1em;
    font-weight: 400;
}
.counter-container .item .label.smaller{
   /*  font-size: 0.8em !important; */
    text-transform: lowercase;
}

.counter-container.small-values .item .val, .counter-container.small-values .item .label{
    font-size: inherit;
}



