.e-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.e-container h1,
.e-container h2,
.e-container h3,
.e-container h4,
.e-container h5 {
  font-family: "Archivo Black";
}

.hidden {
  opacity: 0;
}

.nft-token.embed {
  width: 100%;
  padding-top: 135%;
  position: relative;
  font-size: 1rem;
  background-color: black;
  border-radius: 0.2rem;
  overflow: hidden;
  transition: opacity 0.2s ease-out;
}

.nft-token.embed .video-container {
  position: absolute;
  top: 15%;
  width: 100%;
}

.nft-token.embed .img-container {
  position: absolute;
  top: 5%;
  width: 100%;
}

.nft-token .video-container video,
.nft-token .img-container img {
  width: 100%;
}

.nft-header {
  background: black;
  position: absolute;
  height: 5%;
  top: 0;
  left: 0;
  right: 0;
  background-position: center;
  background-size: cover;
}

.nft-header .token-id {
  font-family: "Archivo Black";
  font-size: 1em;
  padding: 0.1em 0.3em;
  color: white;
  border-radius: 0.4em;
}

.nft-header h5 {
  font-size: 1em;
  text-transform: uppercase;
  white-space: nowrap;
}

.stats-cta,
.traits-cta,
.close-btn {
  cursor: pointer;
  transition: color 0.3s ease-out;
}

.stats-cta:hover,
.traits-cta:hover,
.close-btn:hover {
  color: #ccc;
}

.nft-token .stats,
.nft-token .traits {
  position: absolute;
  top: 5%;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;

  opacity: 0;
  transition: opacity 0.3s ease-out;
  pointer-events: none;
}

.nft-token.stats-visible .stats {
  z-index: 2;
}
.nft-token.stats-visible .traits {
  z-index: 1;
}

.nft-token.stats-visible .stats,
.nft-token.traits-visible .traits {
  opacity: 1;
  pointer-events: all;
}

.traits {
  perspective: 35cm;
}

.stats h1 {
  font-size: 2em;
  margin-top: 1em;
  margin-bottom: 1em;
  text-transform: uppercase;
}

.traits h1 {
  font-size: 2em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.st-container {
  position: absolute;
  top: 12%;
  bottom: 12%;
  right: 0.5em;
  left: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  /* width: 100%; */
  font-family: "Archivo Black";
  font-size: 0.8em;
}

.st-container::-webkit-scrollbar {
  width: 0.5em;
  background: none;

  margin-right: 5px;
}

.st-container::-webkit-scrollbar-thumb {
  border-radius: 9px;
  margin-left: -10px;
}

.stats-table {
  display: table;
  padding-left: 1.5em;
  padding-right: 0.5em;
}

.stats-table > div {
  display: table-row;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
}

.stats-table > div > span {
  display: table-cell;
  color: white;
  padding-top: 0.1em;
  padding-bottom: 0.1em;
}

.st-container .st-head {
  position: sticky;
  top: 0;
}

.faces {
  position: relative;
  width: 100%;
  height: 100%;
  transform: rotateY(0deg);
  transform-style: preserve-3d;
  perspective-origin: center;

  transition: all 0.7s ease-in-out;
}

.faces.flipped {
  transform: rotateY(180deg);
}

.trait-container {
  position: absolute;
  height: 88%;
  top: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  overflow-x: hidden;
  backface-visibility: hidden;
}

.flipped .trait-container {
  pointer-events: none;
}

.trait-container .trait-image {
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.trait-container .trait-image:hover {
  transform: scale(1.2);
}

.trait-container .trait-image img {
  height: 100%;
  max-height: 60px;
  /*  min-width: 40px; */
}

.trait-details {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transform: rotateY(180deg);
  backface-visibility: hidden;
  pointer-events: all;
}

.trait-detail-image img {
  height: 100%;
  max-height: 7em;
}

.trait-title {
  font-size: 2.2em;
  text-transform: uppercase;
  margin-bottom: 0;
}

.redeemable-name {
  font-size: 1.4em;
}

.trait-description {
  color: white;
  font-size: 1.5em;
}

.stat-footer {
  position: absolute;
  height: 12%;
  bottom: 0;
  left: 1em;
  right: 1em;
}

.stat-footer.id-footer {
  height: auto;
  bottom: 1em;
}

.stat-footer.id-footer .token-id {
  font-size: 2em;
}

.stat-footer h2 {
  font-size: 1.5em;
  text-transform: uppercase;
}

.stat-footer .token-id {
  font-family: "Archivo Black";
  font-size: 1.5em;
  padding: 0.1em 0.3em;
  color: white;
  border-radius: 0.4em;
  min-width: 1rem;
  text-align: center;
}

.redeem-link {
  font-size: 0.9em;
  margin-top: 0.6em;
}

.preview-white {
  border: 1px solid #fff;
}

.preview-white .nft-header {
  background: linear-gradient(
    110deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.719) 74%,
    rgba(255, 255, 255, 1) 100%
  );
}

.preview-white h5,
.preview-white .token-id {
  color: black;
}
.preview-white .stat-footer .token-id {
  background: rgb(255, 255, 255);
}

.rising-star-orange {
  border: 1px solid rgb(255, 137, 69);
}
.rising-star-orange .nft-header {
  background: linear-gradient(
    110deg,
    rgba(250, 144, 94, 0.767) 0%,
    rgba(255, 102, 0, 0.384) 74%,
    rgba(255, 150, 31, 0.632) 100%
  );
}
.rising-star-orange.stats-visible .nft-header,
.rising-star-orange.traits-visible .nft-header {
  background: rgb(241, 144, 99);
}

.nft-token.rising-star-orange .stats,
.nft-token.rising-star-orange .traits {
  background-color: #eb4e3d66;
}

.rising-star-orange .stat-footer .token-id {
  background: rgb(241, 144, 99);
}
.rising-star-orange .stats-table > div > span:nth-child(even) {
  background: #85302666;
}
.rising-star-orange .stats-table > div > span:nth-child(odd) {
  background: #10050466;
}
.rising-star-orange .st-container .st-head:nth-child(even) {
  background: rgb(241, 144, 99);
}
.rising-star-orange .st-container .st-head:nth-child(odd) {
  background: #4b1813;
}
.rising-star-orange .stats-table > div > span {
  border: solid 1px #eb4e3d88;
}
.rising-star-orange .st-container::-webkit-scrollbar-thumb {
  background: rgb(241, 144, 99) !important;
}

.allstar-blue {
  border: 1px solid rgba(123, 245, 247);
}
.allstar-blue .nft-header {
  background: linear-gradient(
    110deg,
    rgb(98, 255, 255, 0.6) 0%,
    rgba(0, 255, 213, 0.26) 74%,
    rgba(98, 255, 255, 0.632) 100%
  );
}
.allstar-blue.stats-visible .nft-header,
.allstar-blue.traits-visible .nft-header {
  background-color: rgb(88, 203, 241);
}
.nft-token.allstar-blue .stats,
.nft-token.allstar-blue .traits {
  background-color: rgb(88, 203, 241, 0.4);
}
.allstar-blue .stat-footer .token-id {
  background-color: rgb(88, 203, 241);
}
.allstar-blue .stats-table > div > span:nth-child(even) {
  background: #3c42e033;
}
.allstar-blue .stats-table > div > span:nth-child(odd) {
  background: #10050466;
}
.allstar-blue .st-container .st-head:nth-child(even) {
  background: rgb(88, 203, 241);
}
.allstar-blue .st-container .st-head:nth-child(odd) {
  background: #13154d;
}
.allstar-blue .stats-table > div > span {
  border: solid 1px #3c42e088;
}
.allstar-blue .st-container::-webkit-scrollbar-thumb {
  background: rgb(88, 203, 241) !important;
}

.rebound-green {
  border: 1px solid rgb(217, 247, 119);
}
.rebound-green .nft-header {
  background: linear-gradient(
    110deg,
    rgba(205, 255, 42, 0.712) 0%,
    rgba(102, 255, 0, 0.247) 74%,
    rgba(205, 255, 42, 0.632) 100%
  );
}
.nft-token.rebound-green .stats,
.nft-token.rebound-green .traits {
  background: rgba(165, 188, 88, 0.4);
}

.rebound-green .stat-footer .token-id {
  background-color: rgba(205, 255, 42, 0.712);
}
.rebound-green .stats-table > div > span:nth-child(even) {
  background: #98989833;
}
.rebound-green .stats-table > div > span:nth-child(odd) {
  background: #10050466;
}
.rebound-green .st-container .st-head:nth-child(even) {
  background: rgba(165, 188, 88);
}
.rebound-green .st-container .st-head:nth-child(odd) {
  background: #606060;
}
.rebound-green .stats-table > div > span {
  border: solid 1px #98989888;
}
.rebound-green .st-container::-webkit-scrollbar-thumb {
  background: rgba(165, 188, 88) !important;
}

.block-pink {
  border: 1px solid rgb(255, 126, 249);
}
.block-pink .nft-header {
  background: linear-gradient(
    110deg,
    rgba(250, 106, 255, 0.712) 0%,
    rgba(255, 0, 242, 0.329) 74%,
    rgba(250, 106, 255, 0.632) 100%
  );
}
.block-pink.stats-visible .nft-header,
.block-pink.traits-visible .nft-header {
  /* background-image: url(../../assets/images/card/TopGold-left.png); */
}
.nft-token.block-pink .stats,
.nft-token.block-pink .traits {
  background-color: rgba(250, 106, 255, 0.4);
}
.block-pink .stat-footer .token-id {
  background-color: rgba(250, 106, 255);
}
.block-pink .stats-table > div > span:nth-child(even) {
  background: #bb942e33;
}
.block-pink .stats-table > div > span:nth-child(odd) {
  background: #3b2f0f66;
}
.block-pink .st-container .st-head:nth-child(even) {
  background: rgba(250, 106, 255);
}
.block-pink .st-container .st-head:nth-child(odd) {
  background: #e8c879;
}
.block-pink .stats-table > div > span {
  border: solid 1px rgba(250, 106, 255);
}

.block-pink .st-container::-webkit-scrollbar-thumb {
  background: rgba(250, 106, 255) !important;
}

.gold-tabs {
  padding-left: 1.5em;
  padding-right: 1.5em;
  margin-top: 1.5em;
}

.gold-tabs input {
  display: none;
}

.gold-tabs label {
  font-family: "Archivo Black";
  font-size: 1.4em;
  color: white;
  border-bottom: solid 2px transparent;
  cursor: pointer;
  text-transform: uppercase;
}

.gold-tabs label:hover {
  color: #ccc;
}

.gold-tabs input:checked + label {
  border-color: #edd923;
}

.nft-token.embed.c-xlarge {
  font-size: 1rem;
}

.nft-token.embed.c-large {
  font-size: 0.8rem;
}
.nft-token.embed.c-medium {
  font-size: 0.6rem;
}
.nft-token.embed.c-small {
  font-size: 0.4rem;
}
.nft-token.embed.c-xsmall {
  font-size: 0.3rem;
}

.nft-token.embed.c-xlarge .trait-container .trait-image img {
  height: 100%;
  max-height: 85px;
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}
.nft-token.embed.c-xlarge .trait-detail-image img {
  height: 100%;
  max-height: 90px;
}

.nft-token.embed.c-small .trait-container .trait-image img {
  height: 100%;
  max-height: 50px;
}
.nft-token.embed.c-xsmall .trait-container .trait-image img {
  height: 100%;
  max-height: 30px;
}
