#tnc {
  margin-top: 50px;
  margin-bottom: 50px;
}
#tnc p {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
}

#tnc h1,
#tnc h2,
#tnc h3 {
  font-family: Arial, Helvetica, sans-serif;
}

#tnc h1 {
  font-size: 1.2rem;
  font-weight: 900;
}
#tnc h2 {
  font-size: 1rem;
  font-weight: 500;
}
#tnc h3 {
  font-size: 0.9rem;
  font-weight: 300;
}

ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

ol > li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;
}

li ol > li {
  margin: 0;
}

li ol > li:before {
  content: counters(item, ".") " ";
}

ol > li > ol > li > ol > li {
  counter-increment: listStyle !important;
}
ol > li > ol > li > ol > li:before {
  content: "(" counter(listStyle, lower-alpha) ")" !important;
  display: table-cell;
  padding-right: 0.6em;
}
