.form-bg{
    background:#00000055;
    border-radius: 0.5rem;
}

.form-bg .btn-outline {
    background-color: #ffffff80;
    border-color: #c7c7c7ac;
    background: linear-gradient(
110deg, rgba(200,200,200,0.4) 0%, rgba(200,200,200,0.1) 74%, rgba(200,200,200,0.6320903361344538) 100%);
}

.field-error{
    margin-top: -1rem;
}

.discord-link {
    color: #b79ce6;
  font-weight: 700;
  transition: color 0.3s ease-out;
}

.discord-link:hover {
    color: #fff;
}