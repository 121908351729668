.second-drop-container {
  position: relative;
  background: #000;
  border: 2px solid white;
  padding-top: 120%;
}

.second-drop-container .video-container,
.second-drop-container .img-container {
  position: absolute;
  top: 10%;
  width: 100%;
}

.second-drop-container .video-container video,
.second-drop-container .img-container img {
  width: 100%;
}

.second-drop-container .preview-header {
  background: white;
  position: absolute;
  height: 6%;
  top: 0;
  left: 0;
  right: 0;
  background-position: center;
  background-size: cover;
  border-bottom: 2px solid white;
}

.second-drop-container .preview-header .item {
  height: 100%;
  font-size: 1em;
  text-transform: uppercase;
  white-space: nowrap;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: none;
}
.second-drop-container .preview-header .item h5 {
  font-family: Helvetica;
  font-weight: 900;
  color: #000;
  vertical-align: middle;
  margin-top: 0px; /* need this to clear the default margin */
  margin-bottom: 0px;
}

.second-drop-container .preview-header .item:nth-child(2),
.second-drop-container .preview-header .item:nth-child(2) h5 {
  color: #fff;
  background-color: #000;
}

.second-drop-container .preview-footer {
  position: absolute;
  height: 6%;
  bottom: 0;
  background-position: center;
  background-size: cover;
  font-size: 0.8em;
  color: #fff;
}

.second-drop-container .preview-footer a {
  color: #fff;
  transition: all 0.1s linear;
}
.second-drop-container .preview-footer a:hover {
  text-decoration: none;
  opacity: 0.8;
}

.second-drop-container .drop-header p {
  font-family: "SFProText";
  font-size: 0.8rem;
  font-weight: 700;
  margin-bottom: 1rem;
  line-height: 0.9rem;
}

.second-drop-container .details h6 {
  font-size: 0.8rem;
  margin-bottom: 0;
}

.second-drop-container .details span {
  font-family: "SFProText";
  color: #fff;
}

.second-drop-container .details.status-presale #presaleTitle,
.second-drop-container .details.status-sale #saleTitle,
.second-drop-container .details.status-forge #forgeTitle {
  color: #00b5cc;
}

.second-drop-container .tiers h6 {
  font-size: 0.9rem;
  margin-bottom: 0;
}
.second-drop-container .tiers p {
  font-family: "SFProText";
  font-size: 0.7rem;
  margin-bottom: 0.3rem;
  line-height: 0.8rem;
}

.second-drop-container .info .price:before {
  content: "";
  display: inline-block;
  background: url("../../assets/images/eth.svg") no-repeat;
  width: 1rem;
  height: 1rem;
  background-size: contain;
}

.second-drop-container .discount-type {
  color: #00b5cc;
  font-size: 0.7rem;
}

.second-drop-container #quantity h6 {
  font-size: 0.9rem;
  margin-bottom: 0;
  word-break: break-all;
}
.second-drop-container #quantity p {
  font-family: "SFProText";
  font-size: 0.6rem;
  margin-bottom: 0.3rem;
  line-height: 0.8rem;
  align-self: center;
}

.second-drop-container #quantity .price {
  font-size: 0.7rem;
  line-height: 0.7rem;
  margin-bottom: 0.1rem;
}
.second-drop-container #quantity .price:before {
  content: "";
  display: inline-block;
  background: url("../../assets/images/eth.svg") no-repeat;
  width: 0.7rem;
  height: 0.7rem;
  background-size: contain;
}

.second-drop-container .quantity-row-1,
.second-drop-container .quantity-row-2 {
  border-bottom: 1px solid #fff;
  vertical-align: middle;
}

@media (max-width: 768px) {
  #mintQuantity.second-drop-container {
    padding-top: 0;
    height: 100%;
  }
  #mintQuantity.second-drop-container .position-absolute {
    position: relative !important;
  }
}
@media (max-width: 450px) {
  #dropInfo.second-drop-container {
    padding-top: 0;
    height: 100%;
  }
  #dropInfo.second-drop-container .position-absolute {
    position: relative !important;
  }

  #dropInfo.second-drop-container .tiers,
  #dropInfo.second-drop-container .details,
  #dropInfo.second-drop-container .info {
    margin: 15px 0;
  }
}

@media (max-width: 376px) {
  .second-drop-container .preview-header .item h5 {
    font-size: 0.8em;
  }
  .second-drop-container .preview-footer {
    font-size: 0.6em;
  }
  .second-drop-container .info h5 {
    font-size: 1.1rem;
  }

  #mintQuantity.second-drop-container .col-4 {
    padding: 0;
    text-align: center;
  }
}
