.top-banner {
  width: 100%;
  line-height: 48px;
  text-align: center;
  background-color: #671ce4;
  color: #d2d2d2;
  position: fixed;
  top: 0;
  z-index: 10200;
}

.top-banner a {
  margin-left: 0.5rem;
  color: #d2d2d2;
  font-weight: 600;
  transition: color 0.3s ease-out;
}
.top-banner a:hover {
  color: #ffffff;
  text-decoration: none;
}

.top-banner a img {
  max-height: 24px;
  margin-right: 0.25rem;
}

.top-banner .close-btn {
  position: absolute;
  right: 2rem;
  cursor: pointer;
  font-weight: 700;
}

.welcome-title {
  font-size: 3.1rem;
  line-height: 3.1rem;
}

.step {
  color: #b79ce6 !important;
  font-weight: 600;
  text-align-last: center;
}

.dscrd a {
  color: #b79ce6;
  font-weight: 500;
  transition: color 0.3s ease-out;
}

.dscrd a:hover {
  color: white;
  text-decoration: none;
}

.home h2 {
  font-size: 2.2em;
  line-height: 1.2em;
  font-weight: 600;
}
.home h3 {
  font-size: 1.9em;
  line-height: 1.2em;
  font-weight: 600;
}

.home p {
  color: white;
  font-size: 1rem;
  line-height: 1.4rem;
}

.lead-text {
  color: #e8e6e8;
  text-shadow: 0 1px 1px black;
  line-height: 1.9em !important;
  font-weight: 600 !important;
}

.bshadow {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.95) 50%,
    rgba(0, 0, 0, 0) 100%
  );
}

.hero.roadmap {
  padding: 1rem;
  background-color: rgba(43, 13, 89, 0.72);
  border-radius: 1rem;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-15px);
  }
  100% {
    transform: translatey(0px);
  }
}

.nft-card video {
  width: 100%;
  object-fit: contain;
  object-position: center;
}

.nft-card {
  border: solid 2px white;
  background-color: #ffffff80;
  border-radius: 0.4rem;
  padding: 0.75rem;
  min-width: 180px;
  margin-bottom: 2rem;
}

.nft-type-label {
  font-size: 0.6rem;
  margin-bottom: 0;
}
.nft-name-label {
  font-size: 1rem;
}

.nft-stats h4 {
  font-size: 0.6rem;
  margin: 0;
}
.nft-stats h3 {
  font-size: 0.9rem;
  margin: 0;
}
.nft-stats h5 {
  font-size: 0.55rem;
  margin: 0;
}

.nft-card .btn-outline {
  border-width: 1px;
}

.nft-card.red,
.nft-card.red .btn-outline {
  background: #ff001a38;
  border-color: #e14d5caa;
  background: linear-gradient(
    110deg,
    rgba(255, 0, 0, 0.4) 0%,
    rgba(255, 0, 0, 0.1) 74%,
    rgba(255, 0, 0, 0.6320903361344538) 100%
  );
}
.nft-card.red .btn-outline:hover {
  background-color: #ff001a66;
}

.nft-card.blue,
.nft-card.blue .btn-outline {
  background-color: #0043ff38;
  border-color: #764ee4ac;
  background: linear-gradient(
    110deg,
    rgba(0, 0, 200, 0.4) 0%,
    rgba(0, 0, 200, 0.1) 74%,
    rgba(0, 0, 200, 0.6320903361344538) 100%
  );
}
.nft-card.blue .btn-outline:hover {
  background-color: #0043ff66;
}

.nft-card.silver,
.nft-card.silver .btn-outline,
.btn-outline.silver {
  background-color: #ffffff80;
  border-color: #c7c7c7ac;
  background: linear-gradient(
    110deg,
    rgba(200, 200, 200, 0.4) 0%,
    rgba(200, 200, 200, 0.1) 74%,
    rgba(200, 200, 200, 0.6320903361344538) 100%
  );
}
.nft-card.silver .btn-outline:hover,
.btn-outline.silver:hover {
  background-color: #ffffff60;
}

.nft-card.gold,
.nft-card.gold .btn-outline {
  background-color: #ffc70180;
  border-color: #ebc94f91;
  background: linear-gradient(
    142deg,
    rgba(255, 199, 1, 0.4) 0%,
    rgba(255, 199, 0, 0.1) 74%,
    rgba(255, 199, 1, 0.63) 100%
  );
}
.nft-card.gold .btn-outline:hover {
  background-color: #ffc70160;
}

.nft-card.emerald,
.nft-card.emerald .btn-outline {
  background: rgba(165, 188, 88, 0.4);
  border: 1px solid rgb(217, 247, 119);
  background: linear-gradient(
    110deg,
    rgba(205, 255, 42, 0.712) 0%,
    rgba(102, 255, 0, 0.247) 74%,
    rgba(205, 255, 42, 0.632) 100%
  );
}
.nft-card.emerald .btn-outline:hover {
  background-color: rgba(205, 255, 42, 0.712);
}

.nft-card.pink,
.nft-card.pink .btn-outline {
  background-color: rgba(250, 106, 255, 0.4);
  border: 1px solid rgb(255, 126, 249);
  background: linear-gradient(
    110deg,
    rgba(250, 106, 255, 0.712) 0%,
    rgba(255, 0, 242, 0.329) 74%,
    rgba(250, 106, 255, 0.632) 100%
  );
}
.nft-card.pink .btn-outline:hover {
  background-color: rgba(250, 106, 255);
}

.nft-card.orange,
.nft-card.orange .btn-outline,
.btn-outline.orange {
  background-color: #eb4e3d66;
  border-color: rgb(255, 137, 69);
  background: linear-gradient(
    142deg,
    rgba(250, 144, 94, 0.767) 0%,
    rgba(255, 102, 0, 0.384) 74%,
    rgba(255, 150, 31, 0.632) 100%
  );
}
.nft-card.orange .btn-outline:hover,
.btn-outline.orange :hover {
  background: rgb(241, 144, 99);
}

.nft-card.crystal,
.nft-card.crystal .btn-outline {
  background-color: rgb(88, 203, 241, 0.4);
  border-color: rgba(123, 245, 247);
  background: linear-gradient(
    142deg,
    rgb(98, 255, 255, 0.6) 0%,
    rgba(0, 255, 213, 0.26) 74%,
    rgba(98, 255, 255, 0.632) 100%
  );
}
.nft-card.crystal .btn-outline:hover {
  background-color: rgb(88, 203, 241);
}

.toolboxes {
  border: solid 2px #c7c7c7ac;
  border-radius: 0.4rem;
  padding: 1.5rem 1rem;
}

.toolboxes .btn-outline {
  background-color: #ffffff80;
  border-color: #c7c7c7ac;
  background: linear-gradient(
    110deg,
    rgba(200, 200, 200, 0.4) 0%,
    rgba(200, 200, 200, 0.1) 74%,
    rgba(200, 200, 200, 0.6320903361344538) 100%
  );
}

.toolbox-title {
  text-transform: uppercase;
}

.member-buttons button {
  font-size: 0.8em;
  max-width: 280px;
}

.partners span a img {
  max-height: 80px;
}

.home-stat-container {
  position: relative;
  overflow-x: hide;
  overflow-y: scroll;
  width: 100%;
  font-family: "DrukWideBold";
  font-size: 0.8em;
  max-height: 50vh;
  background-color: #83cadf40;
}

.home-stat-container .st-head {
  position: sticky;
  top: 0;
}

.clp img {
  max-height: 32px;
}

.stats-title {
  background-color: #83cadf40;
  color: white;
}

.home-stat-container .st-head {
  background: rgb(68, 79, 136);
}

.stats-table > div > span {
  border: solid 1px rgba(255, 255, 255, 0.591);
}

@media (max-width: 992px) {
  .card-type-image video {
    margin: auto;
    width: 100%;
  }
  .card-type-image {
    margin-top: auto;
  }
}

@media (max-width: 1200px) {
  .card-type-image {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.purple-box {
  padding: 1rem;
  background-color: rgba(43, 13, 89, 0.72);
  border-radius: 1rem;
}

@media (max-width: 706px) {
  .top-banner {
    line-height: 24px;
  }

  .top-banner .close-btn {
    top: 0;
    line-height: 48px;
    right: 1rem;
  }

  .welcome-title {
    font-size: 1.9rem;
    line-height: 2rem;
  }
}
