.e-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.e-container h1,
.e-container h2,
.e-container h3,
.e-container h4,
.e-container h5 {
  font-family: "Archivo Black";
}

.hidden {
  opacity: 0;
}

.nft-token.embed {
  width: 100%;
  padding-top: 135%;
  position: relative;
  font-size: 1rem;
  background-color: black;
  border-radius: 0.2rem;
  overflow: hidden;
  transition: opacity 0.2s ease-out;
}

.nft-token.embed .video-container {
  position: absolute;
  top: 15%;
  width: 100%;
}

.nft-token.embed .img-container {
  position: absolute;
  top: 5%;
  width: 100%;
  height: 95%;
}

.nft-token.embed .img-container img {
  object-fit: contain;
}

.nft-token .video-container video,
.nft-token .img-container img {
  width: 100%;
}

.nft-header {
  background: black;
  position: absolute;
  height: 5%;
  top: 0;
  left: 0;
  right: 0;
  background-position: center;
  background-size: cover;
}

.nft-header h5 {
  font-size: 1em;
  text-transform: uppercase;
  white-space: nowrap;
}

.stats-cta,
.traits-cta,
.close-btn {
  cursor: pointer;
  transition: color 0.3s ease-out;
}

.stats-cta:hover,
.traits-cta:hover,
.close-btn:hover {
  color: #ccc;
}

.nft-token .stats,
.nft-token .traits {
  position: absolute;
  top: 5%;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;

  opacity: 0;
  transition: opacity 0.3s ease-out;
  pointer-events: none;
}

.nft-token.stats-visible .stats {
  z-index: 2;
}
.nft-token.stats-visible .traits {
  z-index: 1;
}

.nft-token.stats-visible .stats,
.nft-token.traits-visible .traits {
  opacity: 1;
  pointer-events: all;
}

.traits {
  perspective: 35cm;
}

.stats h1 {
  font-size: 2em;
  margin-top: 1em;
  margin-bottom: 1em;
  text-transform: uppercase;
}

.traits h1 {
  font-size: 2em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.st-container {
  position: absolute;
  top: 12%;
  bottom: 12%;
  right: 0.5em;
  left: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  /* width: 100%; */
  font-family: "Archivo Black";
  font-size: 0.8em;
}

.st-container::-webkit-scrollbar {
  width: 0.5em;
  background: none;

  margin-right: 5px;
}

.st-container::-webkit-scrollbar-thumb {
  border-radius: 9px;
  margin-left: -10px;
}

.stats-table {
  display: table;
  padding-left: 1.5em;
  padding-right: 0.5em;
}

.stats-table > div {
  display: table-row;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
}

.stats-table > div > span {
  display: table-cell;
  color: white;
  padding-top: 0.1em;
  padding-bottom: 0.1em;
}

.st-container .st-head {
  position: sticky;
  top: 0;
}

.faces {
  position: relative;
  width: 100%;
  height: 100%;
  transform: rotateY(0deg);
  transform-style: preserve-3d;
  perspective-origin: center;

  transition: all 0.7s ease-in-out;
}

.faces.flipped {
  transform: rotateY(180deg);
}

.trait-container {
  position: absolute;
  height: 88%;
  top: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  overflow-x: hidden;
  backface-visibility: hidden;
}

.flipped .trait-container {
  pointer-events: none;
}

.trait-container .trait-image {
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.trait-container .trait-image:hover {
  transform: scale(1.2);
}

.trait-container .trait-image img {
  height: 100%;
  max-height: 60px;
  /*  min-width: 40px; */
}

.trait-details {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transform: rotateY(180deg);
  backface-visibility: hidden;
  pointer-events: all;
}

.trait-detail-image img {
  height: 100%;
  max-height: 7em;
}

.trait-title {
  font-size: 2.2em;
  text-transform: uppercase;
  margin-bottom: 0;
}

.redeemable-name {
  font-size: 1.4em;
}

.trait-description {
  color: white;
  font-size: 1.5em;
}

.stat-footer {
  position: absolute;
  height: 12%;
  bottom: 0;
  left: 1em;
  right: 1em;
}

.stat-footer.id-footer {
  height: auto;
  bottom: 1em;
}

.stat-footer.id-footer .token-id {
  font-size: 2em;
}

.stat-footer h2 {
  font-size: 1.5em;
  text-transform: uppercase;
}

.stat-footer .token-id {
  font-family: "Archivo Black";
  font-size: 1.5em;
  padding: 0.1em 0.3em;
  color: white;
  border-radius: 0.4em;
  min-width: 1rem;
  text-align: center;
}

.redeem-link {
  font-size: 0.9em;
  margin-top: 0.6em;
}

.red-mars {
  border: 1px solid #eb4e3d;
}
.red-mars .nft-header {
  background-image: url(../../assets/images/card/TopRed1.png);
}
.red-mars.stats-visible .nft-header,
.red-mars.traits-visible .nft-header {
  background-image: url(../../assets/images/card/TopRed1-left.png);
}

.nft-token.red-mars .stats,
.nft-token.red-mars .traits {
  background-color: #eb4e3d66;
}

.red-mars .stat-footer .token-id {
  background-color: #eb4e3d;
}
.red-mars .stats-table > div > span:nth-child(even) {
  background: #85302666;
}
.red-mars .stats-table > div > span:nth-child(odd) {
  background: #10050466;
}
.red-mars .st-container .st-head:nth-child(even) {
  background: #eb4e3d;
}
.red-mars .st-container .st-head:nth-child(odd) {
  background: #4b1813;
}
.red-mars .stats-table > div > span {
  border: solid 1px #eb4e3d88;
}
.red-mars .st-container::-webkit-scrollbar-thumb {
  background: #eb4e3d !important;
}

.blue-neptune {
  border: 1px solid #3d44eb;
}
.blue-neptune .nft-header {
  background-image: url(../../assets/images/card/TopBlue1.png);
}
.blue-neptune.stats-visible .nft-header,
.blue-neptune.traits-visible .nft-header {
  background-image: url(../../assets/images/card/TopBlue1-left.png);
}
.nft-token.blue-neptune .stats,
.nft-token.blue-neptune .traits {
  background-color: #3c42e066;
}
.blue-neptune .stat-footer .token-id {
  background-color: #3c42e0;
}
.blue-neptune .stats-table > div > span:nth-child(even) {
  background: #3c42e033;
}
.blue-neptune .stats-table > div > span:nth-child(odd) {
  background: #10050466;
}
.blue-neptune .st-container .st-head:nth-child(even) {
  background: #3c42e0;
}
.blue-neptune .st-container .st-head:nth-child(odd) {
  background: #13154d;
}
.blue-neptune .stats-table > div > span {
  border: solid 1px #3c42e088;
}
.blue-neptune .st-container::-webkit-scrollbar-thumb {
  background: #3c42e0 !important;
}

.silver-moon {
  border: 1px solid #989898;
}
.silver-moon .nft-header {
  background-image: url(../../assets/images/card/TopGREY1.png);
}
.silver-moon.stats-visible .nft-header,
.silver-moon.traits-visible .nft-header {
  background-image: url(../../assets/images/card/TopGREY1-left.png);
}
.nft-token.silver-moon .stats,
.nft-token.silver-moon .traits {
  background-color: #98989866;
}
.silver-moon .stat-footer .token-id {
  background-color: #989898;
}
.silver-moon .stats-table > div > span:nth-child(even) {
  background: #98989833;
}
.silver-moon .stats-table > div > span:nth-child(odd) {
  background: #10050466;
}
.silver-moon .st-container .st-head:nth-child(even) {
  background: #989898;
}
.silver-moon .st-container .st-head:nth-child(odd) {
  background: #606060;
}
.silver-moon .stats-table > div > span {
  border: solid 1px #98989888;
}
.silver-moon .st-container::-webkit-scrollbar-thumb {
  background: #989898 !important;
}

.gold-evolve {
  border: 1px solid #edd923;
}
.gold-evolve .nft-header {
  background-image: url(../../assets/images/card/TopGold.png);
}
.gold-evolve.stats-visible .nft-header,
.gold-evolve.traits-visible .nft-header {
  background-image: url(../../assets/images/card/TopGold-left.png);
}
.nft-token.gold-evolve .stats,
.nft-token.gold-evolve .traits {
  background-color: #bb942e66;
}
.gold-evolve .stat-footer .token-id {
  background-color: #bb942e;
}
.gold-evolve .stats-table > div > span:nth-child(even) {
  background: #bb942e33;
}
.gold-evolve .stats-table > div > span:nth-child(odd) {
  background: #3b2f0f66;
}
.gold-evolve .st-container .st-head:nth-child(even) {
  background: #bb942e;
}
.gold-evolve .st-container .st-head:nth-child(odd) {
  background: #e8c879;
}
.gold-evolve .stats-table > div > span {
  border: solid 1px #bb942e88;
}

.gold-evolve .st-container::-webkit-scrollbar-thumb {
  background: #e8c879 !important;
}

.gold-tabs {
  padding-left: 1.5em;
  padding-right: 1.5em;
  margin-top: 1.5em;
}

.gold-tabs input {
  display: none;
}

.gold-tabs label {
  font-family: "Archivo Black";
  font-size: 1.4em;
  color: white;
  border-bottom: solid 2px transparent;
  cursor: pointer;
  text-transform: uppercase;
}

.gold-tabs label:hover {
  color: #ccc;
}

.gold-tabs input:checked + label {
  border-color: #edd923;
}

.nft-token.embed.c-xlarge {
  font-size: 1rem;
}

.nft-token.embed.c-large {
  font-size: 0.8rem;
}
.nft-token.embed.c-medium {
  font-size: 0.6rem;
}
.nft-token.embed.c-small {
  font-size: 0.4rem;
}
.nft-token.embed.c-xsmall {
  font-size: 0.3rem;
}

.nft-token.embed.c-xlarge .trait-container .trait-image img {
  height: 100%;
  max-height: 85px;
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}
.nft-token.embed.c-xlarge .trait-detail-image img {
  height: 100%;
  max-height: 90px;
}

.nft-token.embed.c-small .trait-container .trait-image img {
  height: 100%;
  max-height: 50px;
}
.nft-token.embed.c-xsmall .trait-container .trait-image img {
  height: 100%;
  max-height: 30px;
}
